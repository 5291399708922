import React, { useEffect, useState } from "react"
import { SearchBar, EventsPager, Loader, Layout, CenteredLayout } from "@components"
import { getEvents } from "@services"

const Events = () => {
  const [allEvents, setAllEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])

  useEffect(() => {
    const load = async () => {
      let events = await getEvents()
      setAllEvents(events)
      setResults(events)
      setLoading(false)
    }
    load()
  }, [])

  const updateSearch = searchTerm => {
    const searched = []
    allEvents.forEach(event => {
      if (searchTerm.length <= 0 || searchTerm === "") searched.push(event)
      else if (event.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) searched.push(event)
    })
    setResults(searched)
  }

  return (
    <Layout>
      <CenteredLayout className="bg-nwh-light-gray">
        <SearchBar onSearch={updateSearch}></SearchBar>
      </CenteredLayout>
      <CenteredLayout>
        {!loading && (
          <div className="w-full">
            <EventsPager data={results}></EventsPager>
          </div>
        )}
        {loading && <Loader label="loading..." />}
      </CenteredLayout>
    </Layout>
  )
}

export default Events
